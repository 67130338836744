.info-div-header{
    width: 1500px;
    display: inline-block;
    text-align: center;

}
.white{
    color: #ffffff !important;
    border: 0;
    font-size: 20px;
}

.btn{
    border: 0 !important;
    border-radius: 0 !important;
}

.nav-color{
    background-color: rgb(20, 24, 42) !important;
}


header{
    position: fixed;
    width: 100%;
}

.header-text{

    justify-content: center;
    margin-right: -8%

}

.navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 15px;
    text-decoration: none;
}


