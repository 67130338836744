.text-contact{
    display: flex;
    justify-content: center;
    padding-top: 50px;
    min-font-size: 100%;
}

.email:hover{
    font-size:30px;
    color: blue!important;
}

.phone{
    font-size: 20px;
}

.document{
    font-size: 30px;
}

