.lager-image{
    display: block;
    margin: auto;
    border-radius: 10%;
}

.project-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    border-radius: 5%;
}

.text{
    font-size: 22px;
    justify-content: center!important;
    align-items: center!important;
    display: flex;
}

h1{
    color: orange;
    padding-top: 30px !important;
}

h2{
    font-size: 35px;
    justify-content: center!important;
    align-items: center!important;
    display: flex;
    padding-top: 60px;
    color: #e3e380;
}
