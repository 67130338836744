
* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;

}

body {
    font-family: 'Roboto Condensed', sans-serif;
    height: 100%;
    width: 100%;
    background: rgb(25,34,130);
    background: linear-gradient(90deg, rgba(25,34,130,0.9472163865546218) 0%, rgba(176,69,34,0.9808298319327731) 52%, rgba(235,144,59,1) 100%);
}

.main {
    color: rgb(255, 255, 255)
}

footer {
    color: rgba(255, 255, 255, 0.75);
    font-size: 20px;
}


.white {
    color: aliceblue !important;
}

.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

}

footer {
    margin-top: auto;
    background-color: rgba(14, 4, 4, 0.05) !important;
}

.a-footer {
    border: 0;
    text-decoration: none;
    font-size: 24px;
    color: aliceblue !important;
}

.link {
    text-align: center;
}

.main-image {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    width: 400px;
    height: 400px;
}

.created{
    color: #6d9f17;
    font-size: 16px;
}

.text-footer-color{
    color: #ab7b21 !important;
    font-size: 16px !important;
}


