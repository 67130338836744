.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: -ms-device-fixed;

    padding-top: 100px;

}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #ab7b21 transparent #cb8007 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}