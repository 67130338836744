.image{
    border-radius: 20% !important;
    width: 65vh;
    margin: 40px auto;

}

.v-image{
    border-radius: 20% !important;
    display: block;
    max-width: 100%;
    margin: 40px auto;

}

.director{
    margin-bottom: 100px;
}

h1 {
    font-size: 35px;
    margin-left: 20px;
}

.director-text{
    font-size: 35px;
    color: orange;
    justify-content: center;
}


