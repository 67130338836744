.volunteer {
    background-color: rgba(110, 153, 162, 0.07);
    max-width: 320px;
    border-radius: 10%;
}

.volunteer-text-color{
    color: orange;
}

.volunteer-fio-text{
    color: #e3e380;
}

.v-image{
    border-radius: 20% !important;
    display: block;
    max-width: 100%;
    margin: 40px auto;
    height: 40%;
}
