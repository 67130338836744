.main-text-sod{
    padding: 0 !important;
    color: #ce8e1c;
    font-size: 60px;
    justify-content: center!important;
    align-items: center!important;
    display: flex;
}

.main-text-center{
    padding: 0;
    color: white;
    justify-content: center!important;
    align-items: center!important;
    display: flex;
}

.reg{
    justify-content: center!important;
    align-items: center!important;
    display: flex;
}

.img{
    display: flex!important;
    justify-content: center!important;
    width: 80vh;
}

